@import "../../mixins";

.contacts {
  --contact-padding-top: #{rem(110)};
  --contact-padding-bottom: #{rem(110)};

  overflow: hidden;

  @include mediaDesktop {
    --contact-padding-top: #{rem(150)};
    --contact-padding-bottom: #{rem(150)};
  }

  @include mediaBigDesktop {
    --contact-padding-top: #{big(150)};
    --contact-padding-bottom: #{big(150)};
  }

  @include mediaLaptop {
    --contact-padding-top: #{rem(70)};
    --contact-padding-bottom: #{rem(70)};
  }

  @include mediaTablet {
    --contact-padding-top: #{rem(50)};
    --contact-padding-bottom: #{rem(60)};
  }

  @include mediaMobile {
    --contact-padding-top: #{rem(40)};
    --contact-padding-bottom: #{rem(40)};
  }

  &_column {
    & .contacts {
      &__wrapper {
        @include mediaDesktop {
          grid-template-columns: repeat(2, 1fr);
        }
      }
    }
  }

  & .section {
    &__name {
      @include mediaLaptop {
        display: none;
      }
    }
  }

  &__content {
    @include mediaTablet {
      position: relative;
      right: var(--container-offset);

      width: calc(100% + (var(--container-offset) * 2));
    }
  }

  &__wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: stretch;
    justify-content: stretch;
    column-gap: var(--grid-column-gap);
    row-gap: var(--grid-row-gap);

    @include mediaDesktop {
      grid-template-columns: rem(936) 1fr;
    }

    @include mediaBigDesktop {
      grid-template-columns: big(936) 1fr;
    }

    @include mediaTablet {
      row-gap: var(--grid-column-gap);
    }

    @include mediaMobile {
      display: flex;
      flex-direction: column;
      row-gap: rem(12);
    }
  }

  &__map {
    position: relative;

    width: 100%;
    height: auto;

    @include mediaMobile {
      height: rem(340);
    }

    &_fixed {
      grid-column: 1/3;

      height: rem(700);

      @include mediaBigDesktop {
        height: big(700);
      }

      @include mediaLaptop {
        height: rem(525);
      }

      @include mediaTablet {
        height: rem(340);
      }

      @include mediaMobile {
        grid-column: 1/2;
      }
    }

    & .ya-map {
      position: absolute;
      top: 0;
      left: 0;

      width: 100%;
      height: 100%;
    }
  }

  &-info {
    padding: var(--contact-padding-top) calc(var(--container-secondary-offset) - var(--container-offset)) var(--contact-padding-bottom);
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    gap: rem(35);

    background-color: var(--bg-light);

    @include mediaBigDesktop {
      gap: big(35);
    }

    @include mediaLaptop {
      gap: rem(30);
    }

    @include mediaTablet {
      padding: var(--contact-padding-top) var(--container-offset) var(--contact-padding-bottom);
      gap: rem(25);
    }

    @include mediaMobile {
      gap: rem(20);
    }

    &__content {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: start;
      justify-content: start;
      gap: rem(30);

      @include mediaBigDesktop {
        gap: big(30);
      }

      @include mediaLaptop {
        gap: rem(25);
      }

      @include mediaTablet {
        gap: rem(20);
      }

      @include mediaMobile {
        gap: rem(15);
      }
    }

    &__wrapper {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: start;
      justify-content: start;
      gap: rem(30);

      @include mediaBigDesktop {
        gap: big(30);
      }

      @include mediaLaptop {
        gap: rem(25);
      }

      @include mediaMobile {
        gap: rem(20);
      }
    }

    &__list {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      align-items: stretch;
      justify-content: start;
      column-gap: rem(30);
      row-gap: rem(25);

      @include mediaBigDesktop {
        column-gap: big(30);
        row-gap: big(25);
      }

      @include mediaLaptop {
        row-gap: rem(20);
      }

      @include mediaTablet {
        column-gap: rem(15);
      }

      @include mediaMobile {
        row-gap: rem(15);
      }
    }

    &__item {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: start;
      justify-content: end;
      gap: rem(5);

      @include mediaBigDesktop {
        gap: big(5);
      }

      &_row {
        grid-column: 1/3;
      }
    }

    &__row {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: start;
      justify-content: stretch;
      column-gap: rem(30);

      @include mediaBigDesktop {
        column-gap: big(30);
      }

      @include mediaTablet {
        column-gap: rem(15);
      }
    }

    &__name {
      display: block;
      width: 100%;

      color: var(--text-dark-tertiary);
    }

    &__text {
      width: 100%;
      display: block;

      color: var(--text-dark-primary);
      font: var(--font-body-S);
      text-decoration: none;

      transition: color var(--animation-timing) var(--cubic-bezier);
    }

    & a.contacts-info__text {
      position: relative;

      @include hover {
        color: var(--accent-light-primary);
      }

      &::before {
        @include pseudo(10) {}
      }
    }
  }

  &-form {
    padding: var(--contact-padding-top) calc(var(--container-secondary-offset) - var(--container-offset)) var(--contact-padding-bottom);
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    gap: rem(35);

    background-color: var(--bg-light);

    @include mediaBigDesktop {
      gap: big(35);
    }

    @include mediaLaptop {
      gap: rem(30);
    }

    @include mediaTablet {
      padding: var(--contact-padding-top) var(--container-offset) var(--contact-padding-bottom);
      gap: rem(25);
    }

    @include mediaMobile {
      gap: rem(20);
    }

    &__head {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      gap: rem(15);

      @include mediaBigDesktop {
        gap: big(15);
      }
    }

    &__title,
    &__subtitle {
      width: 100%;

      color: var(--text-dark-primary);
    }

    &__form {
      margin-top: auto;

      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      gap: rem(30);

      @include mediaBigDesktop {
        gap: big(30);
      }

      @include mediaTablet {
        gap: rem(20);
      }
    }

    &__input {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      gap: rem(20);

      @include mediaBigDesktop {
        gap: big(20);
      }

      @include mediaTablet {
        gap: rem(10);
      }

      & .text-input {
        width: 100%;
      }
    }

    &__policy {
      color: var(--text-dark-primary);

      @include mediaTablet {
        margin-top: rem(5);
      }

      & a {
        color: var(--accent-light-primary);
        text-decoration: none;

        transition: color var(--animation-timing) var(--cubic-bezier);

        @include hover {
          color: var(--accent-light-secondary);
        }
      }
    }

    &__submit {
      width: 100%;

      & .button {
        width: 100%;
      }
    }
  }
}
